import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link as ScrollLink } from "react-scroll"; // From react-scroll

import "../styles/homestyle.css";

const Header = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className=" navhight">
        <Container>
          <Navbar.Brand href="/">
            <img
              className="logobfheader"
              src={require("../pages/image/bflogo2.png")}
              alt="logo"
              width="160px"
              height="auto"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className=" w-100  pb-lg-0 pb-4 text-center  topclass "
          >
            <Nav className="mx-auto gap-lg-5 gap-2 navfont">
              <Nav.Link className="sobre text-center text-lg-start" href="/">
                {/* react-scroll Link for smooth scrolling */}
                <ScrollLink
                  to="sobrenosotros" // The id of the section
                  smooth={true} // Enables smooth scrolling
                  duration={500} // Scrolling duration
                  offset={-50} // Adjust scroll position for fixed headers
                >
                  Sobre nosotros
                </ScrollLink>
              </Nav.Link>
              <Nav.Link
                className="imprime text-center text-lg-start"
                href="/"
              >
                 <ScrollLink
                  to="imprimeahora" // The id of the section
                  smooth={true} // Enables smooth scrolling
                  duration={500} // Scrolling duration
                  offset={-50} // Adjust scroll position for fixed headers
                >
                Imprime ahora
                </ScrollLink>
              </Nav.Link>
              <Nav.Link className="sobre text-center text-lg-start" href="/">
                <ScrollLink
                  to="proyectos" // The id of the section
                  smooth={true} // Enables smooth scrolling
                  duration={500} // Scrolling duration
                  offset={-50} // Adjust scroll position for fixed headers
                >
                  Proyectos
                </ScrollLink>
              </Nav.Link>
            </Nav>
            <Nav>
              {/* <img
                className="text-center whatsapp text-lg-start mx-auto mx-lg-0 mt-lg-0 mt-2"
                src={require("./image/wtsapbigfoot.png")}
                alt="whatsapp"
                width="50px"
                height="50px"
              /> */}
              <Navbar.Brand href="https://wa.me/3318055852">
              <i className="fab fa-whatsapp text-center whatsapp text-lg-start mx-auto mx-lg-0 mt-lg-0 mt-2"></i>
          </Navbar.Brand>

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
