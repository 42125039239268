import { Route, Routes } from 'react-router-dom';
import './App.css';
import HomePage from './pages/HomePage';
import PrivacyPage from './pages/Privacy';
import TermsPage from './pages/Terms';

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/privacidad' element={<PrivacyPage/>}/>
        <Route path='/terminosycondiciones' element={<TermsPage/>}/>
      </Routes>
    </>
  );
}

export default App;
