import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const data = [
  {
    title: "Señalética",
    description: "Sueños gigantes, publicidad enorme",
    details:
      "Perfecta si necesitas dar a conocer tus productos y servicios en espectaculares.",
    image: require("./image/huelloiconbgsec4.png"), // Add corresponding image paths here
  },
  {
    title: "Rotulación vehicular",
    description: "Que la gente te voltee a ver",
    details:
      "Nuestro equipo está capacitado para realizar un trabajo de calidad.",
    image: require("./image/icon4.png"), // Add corresponding image paths here
  },
  {
    title: "Serigrafía",
    description: "Personaliza tus envases",
    details:
      "Tenemos la maquinaria y personal necesario para personalizar tus materiales.",
    image: require("./image/icon2.png"), // Add corresponding image paths here
  },
  {
    title: "Off-set",
    description: "Grandes negocios, grandes tirajes",
    details:
      "Es perfecto para cubrir tus necesidades de impresión que requiera grandes tirajes.",
    image: require("./image/icon5.png"), // Add corresponding image paths here
  },
  {
    title: "Sublimación",
    description: "Viste a tu personal en HD",
    details:
      "La sublimación nos permite imprimir sobre una variedad infinita de telas y rígidos.",
    image: require("./image/icon3.png"), // Add corresponding image paths here
  },
  {
    title: "Flexografía",
    description: "Etiquétate a lo grande",
    details:
      "Somos especialistas en etiquetas; la flexografía es perfecta para necesidades de alto volumen.",
    image: require("./image/icon6.png"), // Add corresponding image paths here
  },
];
const items = [
  { id: 1, title: "Vinil", imgSrc: "./image/roll1.png" },
  { id: 2, title: "Lona", imgSrc: "./image/roll2.png" },
  { id: 3, title: "Estireno", imgSrc: "./image/roll3.png" },
  { id: 4, title: "Acrílico", imgSrc: "./image/roll4.png" },
  { id: 5, title: "Coroplast", imgSrc: "./image/roll5.png" },
  { id: 6, title: "MDF", imgSrc: "./image/roll6.png" },
  { id: 7, title: "Trovicel", imgSrc: "./image/roll7.png" },
  { id: 8, title: "Alucobond", imgSrc: "./image/roll8.png" },
  { id: 9, title: "Foam board (Cartón pluma)", imgSrc: "./image/roll9.png" },
  { id: 10, title: "Telas", imgSrc: "./image/roll10.png" },
];

const itemsdetail = [
  {
    id: 1,
    title: "Vinil",
    name: "Tipos",
    tipos:
      "Brillante, matte, microperforado, esmerilado, automotriz, recorte, wallgraphics, transparente, imitación de madera y metal, pintarrón ¡y más!",
    aplicaciones:
      "Vallas publicitarias, aparadores, señalética, impresión en calidad fotográfica, muros, cobertura de vidrios y ventanas.",
  },
  {
    id: 2,
    title: "Lona",
    name: "Tipos",

    tipos: "Front, Mesh, blackout, traslúcida.",
    aplicaciones:
      "Espectaculares, banners, letreros, muros, vallas, punto de venta ¡y más!",
  },
  {
    id: 3,
    title: "Estireno",
    name: "Calibre",

    tipos: "15, 20, 30, 40, 60, 80 y 100",
    aplicaciones:
      "Señalética, displays, vestidura de punto de venta, estructuras punto de venta, decoración. ",
  },
  {
    id: 4,
    title: "Acrílico",
    name: "Espesores en mm",

    tipos:
      "2, 3, 4, 5, 6, 8, 10 y 20 mm. Cristal, opal, sólido, traslúcido, mate, neón y espejo.",
    aplicaciones:
      "Displays, decoración, estructuras, señalética, mobiliario ¡y más!",
  },
  {
    id: 5,
    title: "Coroplast",
    name: "Espesores en mm",

    tipos: "1 a 13mm",
    aplicaciones:
      "Cajas, displays, decoración, estructuras, señaletica, mobiliario ¡y más!",
  },
  {
    id: 6,
    title: "MDF",
    name: "Espesores en mm",

    tipos: "3, 5, 6, 8, 10, 12, 15, 18, 25.",
    aplicaciones: "Muebles,  estructuras, señalética,  ¡y más!",
  },
  {
    id: 7,
    title: "Trovicel",
    name: "Espesores",

    tipos: "1 a 19mm",
    aplicaciones:
      "Letreros, señalética industrial y publicitaria, stoppers, cenefas, góndolas, posters, decoración, displays,  ¡y más!",
  },
  {
    id: 8,
    title: "Alucobond",
    name: "Espesores",

    tipos: "4mm",
    aplicaciones:
      "Revestimiento, fachadas, aplicaciones arquitectónicas, decoración, stands, muebles, señalética industrial y publicitaria, ¡y más!",
  },
  {
    id: 9,
    title: "Foam board (Cartón pluma)",
    name: "Espesores",

    tipos: "1/8” (3mm), 3/16” (5mm) y 1/2” (13mm)",
    aplicaciones:
      "Impresión, señalética interiores, stands, displays, arte, fotografía, decoración, manualidades ¡y más!",
  },
  {
    id: 10,
    title: "Telas",
    name: "Tipos",

    tipos:
      "Fibras sintéticas; rayón, nylon, licra, hidrosedal, seda fría, popelín, muselina, satén, neopreno, duplex deportivo.",
    aplicaciones:
      "Impresión, señalética interiores, stands, displays, arte, fotografía, decoración, manualidades ¡y más!",
  },
  // Add the rest of your items here...
];
const settings = {
  infinite: true,
  speed: 600,
  slidesToShow: 5,
  slidesToScroll: 10,
  // autoplay: true,
  autoplaySpeed: 0,
  pauseOnHover: false,
  centerMode: true, // Ensures images are centered
  focusOnSelect: true, // Makes the selected image focused
};

const images = [
  "./image/cklass.png",
  "./image/alpha.png",
  "./image/bigcountry.png",
  "./image/bopisa.png",
  "./image/calint.webp",
  // "./image/gromp.png",
  // "./image/dent.png",
  // "./image/omg.png",
  // "./image/hava.png",
  // "./image/public.png",
  // "./image/addids.png",
  // "./image/disn.png",
  // "./image/liverp.png",
  // "./image/amzo.png",
  // "./image/ipg.png",
];

const Hualla = () => {
  const [currentImage, setCurrentImage] = useState(
    require("./image/huelloiconbgsec4.png") // Default image
  );

  const location = useLocation();
  useEffect(() => {
    if (location.hash === "#proyectos") {
      const element = document.getElementById("proyectos");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  const [hoveredId, setHoveredId] = useState(null);
  return (
    <>
      <div className="hullsec1">
        <div className="hellaimg">
          <img
            id="proyectos"
            className="sizehuella"
            src={require("./image/huellasec4.png")}
            alt="huella"
            width="410px"
            height="auto"
          />
        </div>
        <div className="tecnbox">
          <div className="tecni">Técnicas de impresión</div>
          {/* <div className="boxtecni">
            <div className="titletecni">Señalética</div>
            <div className="descteni">
              Sueños gigantes, publicidad enorme <br />
              <span className="decspan">
                {" "}
                Perfecta si necesitas dar a conocer tus productos y servicios en
                espectaculares.
              </span>
            </div>
          </div> */}
          <div className="allboxes">
            {data.map((item, index) => (
              <div
                key={index}
                className="boxtecni"
                onMouseEnter={() => setCurrentImage(item.image)} // Update image on hover
                onMouseLeave={() =>
                  setCurrentImage(require("./image/huelloiconbgsec4.png"))
                } // Reset to default image
                onTouchStart={() => setCurrentImage(item.image)}
                onTouchEnd={() =>
                  setCurrentImage(require("./image/huelloiconbgsec4.png"))
                }
              >
                <div className="titletecni">{item.title}</div>
                <div className="descteni">
                  {item.description}
                  <br />
                  <span className="decspan">{item.details}</span>
                </div>
              </div>
            ))}

            <div className="bgpic">
              <img
                className="dnoneimg "
                src={currentImage}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
          </div>
          <div className="matriandpic ">
            <div className="matrial">
              Material en los que{" "}
              <span className="Quespan"> hacemos realidad </span>
              tus ideas
            </div>
            <div className="lefthul">
              <img
                className="matleft"
                src={require("./image/hullleft.png")}
                alt="huella"
                width="170px"
                height="auto"
              />
            </div>
            <div className="righthul">
              <img
                className="matright"
                src={require("./image/hullright.png")}
                alt="huella"
                width="215px"
                height="auto"
              />
            </div>
            <div className="imgtitle">
              {items.map((item) => (
                <div
                  key={item.id}
                  className="image-container"
                  //   style={{
                  //     backgroundColor:
                  //       item.id === 2 ? "#F5DBD01A" : "transparent", // Apply background color for the second image
                  //   }}
                  onMouseEnter={() => setHoveredId(item.id)}
                  onMouseLeave={() => setHoveredId(null)}
                  onTouchStart={(e) => {
                    e.preventDefault();
                    setHoveredId(item.id);

                    // Ensure hover effect doesn't stay stuck
                    // setTimeout(() => setHoveredId(null), 3000); // 3 sec timeout
                  }}
                  onTouchEnd={() => setHoveredId(null)}
                >
                  {hoveredId === item.id ? (
                    <div className="imgtile2">
                      {itemsdetail
                        .filter((detail) => detail.id === hoveredId)
                        .map((detail) => (
                          <div className="imgchange" key={detail.id}>
                            <div className="vinil">{detail.title}</div>
                            <div className="tipos">{detail.name}</div>
                            <div className="tiposdecs">{detail.tipos}</div>
                            <div className="tipos applic">Aplicaciones</div>
                            <div className="tiposdecs">
                              {detail.aplicaciones}
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <>
                      <div className="vinil">{item.title}</div>

                      <div className="imhpos">
                        <img
                          className="vilimg"
                          src={require(`${item.imgSrc}`)}
                          alt={item.title}
                          width="370px"
                          height="250px"
                        />
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
            {/* <div className="imgchange">
              <div className="vinil">Vinil</div>
              <div className="tipos">Tipos</div>
              <div className="tiposdecs">
                Brillante, matte, microperforado, esmerilado, automotriz,
                recorte, wallgraphics, transparente, imitación de madera y
                metal, pintarrón ¡y más!
              </div>
              <div className="tipos applic">Aplicaciones</div>
              <div className="tiposdecs">
                Vallas publicitarias, aparadores, señalética, impresión en
                calidad fotográfica, muros, cobertura de vidrios y ventanas. 
              </div>
            </div> */}

            <div className="circlehul">
              <img
                className="circle"
                src={require("./image/halfcicle.png")}
                alt="huella"
                width="215px"
                height="auto"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="fiveimgbg">
        <div className="nuest">Nuestros clientes</div>
        <div className="allsaleimg d-none">
          <Slider {...settings}>
            <div>
              <img
                className="cklas "
                src={require("./image/cklass.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="alpha"
                src={require("./image/alpha.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="bigcoutry"
                src={require("./image/bigcountry.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="bipos"
                src={require("./image/bopisa.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="calint"
                src={require("./image/calint.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>

            <div>
              <img
                className="calint"
                src={require("./image/gromp.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="calint"
                src={require("./image/dent.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="calint"
                src={require("./image/omg.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>

            <div>
              <img
                className="calint"
                src={require("./image/hava.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="calint"
                src={require("./image/public.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="calint"
                src={require("./image/addids.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="calint"
                src={require("./image/disn.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="calint"
                src={require("./image/liverp.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="calint"
                src={require("./image/amzo.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
            <div>
              <img
                className="calint"
                src={require("./image/ipg.png")}
                alt="huella"
                width="auto"
                height="auto"
              />
            </div>
          </Slider>
        </div>

        <div className="image-scroll-container ">
          <div className="image-scroll">
            {images.map((src, index) => (
              <img
                key={index}
                src={require(`${src}`)}
                alt={`scroll-img-${index}`}
              />
            ))}
            {/* Duplicate for smooth loop */}
            {images.map((src, index) => (
              <img
                key={`duplicate-${index}`}
                src={require(`${src}`)}
                alt={`scroll-img-${index}`}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Hualla;
