import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const TermsPage = () => {
  return (
      <>
        <Header />
        <div className="content-container">
          <div className="section">
		  <div className="bgsecpriv">
  <h1>TÉRMINOS Y CONDICIONES</h1>
  <h2>1. Introducción </h2>
  <p>Bienvenido al sitio web de Big Foot Print (https://www.bfprint.com.mx).
  Al acceder y utilizar este sitio web, usted acepta cumplir con los presentes Términos y Condiciones. Si no está de acuerdo con estos términos, le recomendamos que no utilice nuestros servicios.</p>
  <h2>2. Identificación del responsable</h2>
  <p>
  Big Foot Print es una marca de IMPRESIONES OOH DE MÉXICO S.A. DE C.V., con domicilio en Calle Melchor Ocampo número 1500, Interior 2, Colonia El Vigía, C.P. 45140, Zapopan, Jalisco, México.
  </p>
  <h2>3. Definición de Servicios</h2>
  <p>Big Foot Print es una empresa especializada en impresión a gran escala y soluciones de rotulación para publicidad exterior e interior. Ofrecemos la producción de materiales impresos en alta calidad para anuncios espectaculares, vallas publicitarias, cartelería, señalización corporativa y personalización de productos desechables. Nuestros servicios están dirigidos a empresas que buscan materiales impresos duraderos y de alto impacto para potenciar su visibilidad y comunicación de marca.</p>
	<h2>4. Uso de Nuestros Servicios </h2>
	<p>El usuario se compromete a hacer un uso adecuado del sitio web y de nuestros
servicios, evitando cualquier acción que pueda perjudicar la operación de Big Foot Print o a terceros. Nos reservamos el derecho de suspender o cancelar el acceso a
nuestros servicios en caso de incumplimiento de estos términos.</p>
  <h2>5. Propiedad Intelectual</h2>
  <p>
  Todos los contenidos de este sitio web, incluidos textos, imágenes, logotipos y
  diseños, son propiedad de Big Foot Print o cuentan con las licencias necesarias para su uso. Queda prohibida su reproducción, distribución o modificación sin autorización expresa.
  </p>

  <h2>6. Confidencialidad </h2>
  <p>
  Big Foot Print garantiza la protección de la información proporcionada por los usuarios y clientes, asegurando que no será compartida con terceros sin su consentimiento, salvo requerimiento legal.
  </p>

  <h2>7. Recopilación y Uso de Datos Personales </h2>
  <p>
  Big Foot Print podrá recopilar y utilizar datos personales conforme a lo establecido en
nuestro Aviso de Privacidad, el cual puede consultarse dentro de nuestro sitio web. Así mismo, el usuario podrá manifestar su negativa para el uso de sus datos personales con fines de mercadotecnia o prospección comercial.
  </p>
  <h2>8. Pagos y Facturación </h2>
  <p>
  En caso de contratar nuestros servicios, los pagos deberán realizarse según los
términos y condiciones acordados. Se emitirá factura conforme a la normatividad
vigente en México. Big Foot Print no se responsabiliza por errores en la información fiscal proporcionada por el cliente.
  </p>
  <h2>9. Limitación de Responsabilidad</h2>
  <p>Big Foot Print no se hace responsable por: - La exactitud, veracidad o actualidad de la información publicada en el sitio web. - Daños derivados del uso del sitio web o de la imposibilidad de acceder al mismo. - Contenidos de terceros enlazados desde nuestro sitio web.</p>
  <h2>10. Resolución de Conflictos</h2>
  <p>
  En caso de controversia derivada del uso de nuestros servicios, se buscará una
solución amistosa entre las partes. Si no se llega a un acuerdo, el conflicto será
sometido a la jurisdicción de los tribunales competentes de Guadalajara, Jalisco.
  </p>
  <h2>
  11. Modificaciones a los Términos y Condiciones
  </h2>
  <p>
  Nos reservamos el derecho de modificar estos términos en cualquier momento. Los
cambios serán publicados en esta página y entrarán en vigor desde su publicación.
  </p>
  <h2>12. Legislación Aplicable y Jurisdicción</h2>
  <p>Estos Términos y Condiciones se rigen por las leyes de México. Cualquier controversia
  será resuelta ante los tribunales competentes de Guadalajara, Jalisco.</p>
<h2>13. Contacto </h2>
<p>Para cualquier duda sobre estos Términos y Condiciones, puede contactarnos a
través de nuestro sitio web https://www.bfprint.com.mx o en nuestro domicilio en Calle Melchor Ocampo número 1500, Interior 2, Colonia El Vigía, C.P. 45140, Zapopan, Jalisco, México.</p>
</div>

          </div>

        </div>
        <Footer />
      </>
  );
};

export default TermsPage;
