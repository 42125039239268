import React from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll"; // From react-scroll

const Footer = () => {
  return (
    <>
      <div className="footer ">
        <div className="footeright  ">
          <Link to="/" className="text-decoration-none footlink ">
            {/* react-scroll Link for smooth scrolling */}
            <ScrollLink
              to="sobrenosotros" // The id of the section
              smooth={true} // Enables smooth scrolling
              duration={500} // Scrolling duration
              offset={-50} // Adjust scroll position for fixed headers
            >
              Sobre nosotros
            </ScrollLink>
          </Link>
          <Link to="/" className="text-decoration-none footlink  ">
          <ScrollLink
              to="imprimeahora" // The id of the section
              smooth={true} // Enables smooth scrolling
              duration={500} // Scrolling duration
              offset={-50} // Adjust scroll position for fixed headers
            >
              Imprime ahora
            </ScrollLink>
          </Link>
          <Link to="/" className="text-decoration-none footlink ">
            <ScrollLink
              to="proyectos" // The id of the section
              smooth={true} // Enables smooth scrolling
              duration={500} // Scrolling duration
              offset={-50} // Adjust scroll position for fixed headers
            >
              Proyectos
            </ScrollLink>
          </Link>
          {/* <img
            className="whatsapp whatfot "
            src={require("./image/wtsapbigfoot.png")}
            alt="whatsapp"
            width="50px"
            height="50px"
          /> */}
          <a href="https://wa.me/3318055852" target="_blank" rel="noreferrer">
            <i className="fab fa-whatsapp  whatsapp  "></i>
          </a>
        </div>

        <div className="footerbelow">
          <div>
            <img
              className="logobffooter"
              src={require("./image/bfootfooter.png")}
              alt="flogo"
              width="268px"
              height="auto"
            />
            <div className="magnif ">
              ¡Magnifica la impresión de tu negocio!
            </div>
            <div className="avisolink  ">
              <Link to="/privacidad" className="text-decoration-none linkaviso">Aviso de privacidad</Link>|<div><Link to="/terminosycondiciones" className="text-decoration-none linkaviso">Términos y condiciones</Link></div>
            </div>
          </div>

          <div className="santcont">
            <div className="socials">
              <a href="https://www.facebook.com/profile.php?id=100064087662646" target="_blank" rel="noreferrer"><i className="fab fa-facebook facebook"/></a>
              <a href="https://www.linkedin.com/in/bigfoot-print/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin linkedin"/></a>
              <a href="https://www.instagram.com/bigfoot.print/" target="_blank" rel="noreferrer"><i className="fab fa-instagram instagram"/></a>
            </div>
            <a className="mart33" href="https://maps.app.goo.gl/bxBKCbNvGQwJXW5u5" target="_blank" rel="noreferrer">Calle Melchor Ocampo 1500, El Vigía, 45140 Zapopan, Jal.</a>
            <div className="mart33"><a href="tel:33-1540-8239" style={{"textDecoration": 'none', "color": "inherit"}}>33.1540.8239</a></div>
            <a href="mailto:ventas@bfprint.com.mx" target="_blank" className="mart33" rel="noreferrer">ventas@bfprint.com.mx</a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
