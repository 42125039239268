import React, { useState } from "react";
import Form from "react-bootstrap/Form";

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    employeeCount: "",
    email: "",
    phone: "",
    service: "",
    requirements: "",
    source: "BIGFOOT"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const webhookUrl = "https://hook.us1.make.com/y5m1qh979naqpf73toqwx8jmq51zbjnx";

    if (formData.email === '') {
      alert("Por favor revisa us datos y vuelve a intentarlo");
      return
    }

    try {
      const response = await fetch(webhookUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("¡Gracias por contactarnos! En un lapso de 24hrs uno de nuestros especialistas se pondrá en contacto contigo");
        setFormData({
          fullName: "",
          companyName: "",
          employeeCount: "",
          email: "",
          phone: "",
          service: "",
          requirements: "",
        });
      } else {
        alert("Error al enviar tus datos.");
      }
    } catch (error) {
      alert("Ha habido un error por favor intenta de nuevo.");
    }
  };

  return (
    <>
      <div className="conatctbg" id="imprimeahora">
        <div className="empezer">¿No sabes por dónde empezar?</div>
        <div className="ayuda">Te ayudamos a dar el primer paso...</div>
        <div className="contactform">
          <div className="formtext">
            Te asesoramos en material, procesos, acabados, técnicas, asegurando
            que todo salga perfecto.
          </div>
          <div>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3 nameform" controlId="formBasicName">
                <Form.Control
                  className="nameform"
                  type="text"
                  placeholder="Nombre completo"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3 nameform" controlId="formBasicCompany">
                <Form.Control
                  className="nameform"
                  type="text"
                  placeholder="Nombre de la empresa"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3 nameform" controlId="formBasicEmployees">
                <Form.Control
                  className="nameform"
                  type="text"
                  placeholder="Cantidad de empleados"
                  name="employeeCount"
                  value={formData.employeeCount}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3 nameform" controlId="formBasicEmail">
                <Form.Control
                  className="nameform"
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3 nameform" controlId="formBasicPhone">
                <Form.Control
                  className="nameform"
                  type="text"
                  placeholder="Teléfono"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3 nameform" controlId="formBasicService">
                <Form.Control
                  className="nameform"
                  type="text"
                  placeholder="Servicio"
                  name="service"
                  value={formData.service}
                  onChange={handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-3 nameform" controlId="formBasicTextarea">
                <Form.Control
                  as="textarea"
                  className="nameform textarfaeh"
                  rows={3}
                  placeholder="Requerimientos (volumen, tamaño, especificaciones)"
                  name="requirements"
                  value={formData.requirements}
                  onChange={handleChange}
                />
              </Form.Group>

              <button type="submit" className="butnform">Solicitar cotización</button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
