import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const PrivacyPage = () => {
  return (
      <>
        <Header />
        <div className="content-container">
          <div className="section">
		  <div className="bgsecpriv">
  <h1>AVISO DE PRIVACIDAD</h1>
  <p>
    De conformidad con lo dispuesto por la Ley Federal de Protección de Datos
    Personales en Posesión de los Particulares en adelante denominada como la
    “Ley”, se emite el presente Aviso de Privacidad en los siguientes términos:
  </p>
  <h1>FECHA DE ÚLTIMA ACTUALIZCION</h1>
  <p>31 de enero de 2025</p>
  <h1>RESPONSABLE DE LOS DATOS PERSONALES</h1>
  <p>
    Para efectos del presente Aviso de Privacidad, IMPRESIONES OOH DE MEXICO
    S.A. DE C.V., (en adelante “Big Foot Print”), sociedad constituida conforme
    a las leyes mexicanas, con domicilio fiscal ubicado en Calle Melchor Ocampo
    número 1500, Interior 2, Colonia El Vigía, C.P. 45140, en la ciudad de
    Zapopan, Jalisco, es la Responsable de la obtención, divulgación,
    almacenamiento, uso, manejo, aprovechamiento, y/o disposición de los datos
    personales, laborales y empresariales que representan legal o
    comercialmente a sus Clientes y/o Proveedores (en adelante los “Titulares”).
  </p>
  <h1>OBJETO</h1>
  <p>El presente Aviso de Privacidad tiene por objeto regular el uso y la
    protección de los datos personales del Titular en posesión de la Responsable
    conforme a la Ley.</p>
	<h1>DATOS RECABADOS</h1>
	<p>Los datos personales que la Responsable recaba de los Titulares y/o de la Empresa que representan los Titulares son de manera enunciativa mas no limitativa los siguientes:</p>
  <h2>Datos de la Empresa</h2>
  <ul>
    <li>Nombre o Razón Social</li>
    <li>domicilio</li>
    <li>número telefónico</li>
    <li>
      referencias de principales clientes, nombre de la compañía, dirección,
      teléfono y años de relación comercial
    </li>
    <li>comprobante de domicilio</li>
    <li>registro federal de contribuyentes y cédula de identificación fiscal</li>
    <li>acta constitutiva</li>
    <li>poder notarial</li>
    <li>
      datos bancarios (en particular la CLABE Interbancaria, Nombre del Banco,
      Número de Cuenta, Tipo de Cuenta y Nombre)
    </li>
    <li>estados de cuenta bancarios</li>
    <li>opinión de cumplimiento de obligaciones fiscales</li>
  </ul>
  <h2>Datos del Titular</h2>
  <ul>
    <li>Nombre completo e identificación oficial</li>
    <li>domicilio y comprobante de domicilio</li>
    <li>cargo institucional que desempeña</li>
    <li>correo electrónico</li>
    <li>número telefónico</li>
    <li>CURP</li>
    <li>registro federal de contribuyentes y cédula de identificación fiscal</li>
    <li>estados de cuenta bancarios</li>
    <li>opinión de cumplimiento de obligaciones fiscales</li>
  </ul>
  <p>
    El Titular declara que cuenta con la autorización correspondiente para
    transferir los datos de terceros recabados por Big Foot Print Sus datos personales o corporativos son recabados a través de los siguientes medios: físicamente y/o por correo electrónico.
  </p>

  <h1>FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES</h1>
  <p>
  La información personal del Titular, servirá a la Responsable para (i) elaborar una base de datos de los clientes, proveedores y contratistas con los que cuenta Big Foot Print en beneficio de la operación de las áreas internas de Big Foot Print, (ii) generar y dar seguimiento a las órdenes de compra y de pedido en curso, (iii) para investigar y confirmar la buena reputación y prestigio comercial del Titular previo a la formalización comercial entre la Responsable y el Titular, (iv) para realizar su registro como Cliente y/o Proveedor dentro de los sistemas internos de la Responsable, (v) para cumplir con las declaraciones fiscales de la Responsable, (vi) para la creación de un directorio de Clientes y Proveedores con fines estadísticos, financieros, administrativos y de calidad exclusivamente para el uso interno de Big Foot Print., (vii) para enviar información y publicidad propia de Big Foot Print y llevar a cabo su actividad principal y proveerle de los productos o servicios que le solicite, requiera y/o pueda requerir.
De igual forma, dicha información tendrá como finalidad la identificación del Titular para su asesoramiento, atención y seguimiento; y podrá difundirse exclusivamente a las personas que resulten indispensables, a efecto de llevar a cabo las actividades o los instrumentos para cumplir con las obligaciones derivadas de la actividad principal de la Responsable. La divulgación de los datos que han sido proporcionados se realizará atendiendo los principios de consentimiento, información, calidad, finalidad, lealtad y proporcionalidad, además, servirá como información de contacto, elaboración de documentos, diseño de estrategias y, en su caso, cobranza.

Los datos personales tendrán el uso apropiado para el cual fueron obtenidos y podrán ser integrados a una base de datos, misma que será creada por la Responsable o algún tercero al cual se le encomiende. Dicha base de datos no será del dominio público y podrá tener datos adicionales generados por medios propios.

De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que no son necesarias para que la Responsable otorgue el producto o brinde el servicio solicitado, sin embargo nos permiten y facilitan brindarle una mejor atención:

•	Informarle u ofrecerle al Titular promociones productos y servicios en general, así como cualquier otro producto o servicio relacionado, como pueden ser, sin limitación, entrega de promociones, servicios especiales, folletos informativos, encuestas y otros productos y servicios de la Responsable.

•	Registro de actividad de visitantes en el sitio web antes de Big Foot Print.

•	Todas aquellas similares o análogas a las anteriores.

En caso de que desee que sus datos personales no sean tratados para estos fines secundarios, desde este momento el Titular nos puede comunicar lo anterior a través de un correo electrónico enviado a la dirección: avisodeprivacidad@bfprint.com.mx haciendo alusión en el asunto de dicho correo al presente Aviso de Privacidad.

La negativa para el uso de sus datos personales para dichas finalidades secundarias no podrá ser un motivo para que le neguemos los servicios y productos que solicita o contrata con la Responsable.

De igual forma los datos personales recabados podrán ser tratados con la finalidad de que la Responsable pueda atender requerimientos legales de autoridades competentes.
  </p>

  <h1>TRANSFERENCIA DE DATOS</h1>
  <p>
  Toda transferencia de datos a nuestros colaboradores se realiza atendiendo a que asumen las mismas obligaciones que la Responsable, en los términos del presente Aviso de Privacidad y de la Ley.

La transferencia de información personal del Titular a terceros (distintos a nuestros colaboradores), se realizará exclusivamente si usted da su consentimiento para proporcionarla, a excepción de la información a instituciones o autoridades de carácter financiero.

De igual forma, la Responsable podrá transferir datos e información personal del Titular a terceros que tengan relación directa o indirecta con la Responsable y la misma sea necesaria para llevar a cabo sus actividades.

Asimismo, la Responsable podrá transferir datos e información personal del Titular a sus sociedades filiales y afiliadas, así como a otras sociedades que tengan relación con la Responsable. Lo anterior, para que le brinden información y avisos respecto a sus actividades.

Se entenderá que el Titular ha otorgado su consentimiento, sin vicio alguno, para la transferencia de datos personales si no manifiesta su oposición, de conformidad a lo dispuesto en el tercer párrafo del artículo 8 de la Ley.
  </p>
  <h1>DATOS PERSONALES SENSIBLES</h1>
  <p>
  Le informamos que en caso de ser requeridos datos personales sensibles del Titular, de conformidad a la Ley, la Responsable se compromete a que los mismos sean tratados bajo medidas de seguridad, siempre garantizando su confidencialidad y consentimiento para el tratamiento de sus datos. Al hacer uso del sitio web antes mencionado, Usted consiente y autoriza que sus datos personales sensibles sean tratados conforme a lo previsto en el presente Aviso de Privacidad y bajo lo establecido por la Ley.
  </p>
  <h1>EXCEPCIONES</h1>
  <p>Cabe señalar que de conformidad con la Ley existen supuestos en los cuales su consentimiento no es necesario para el tratamiento de sus datos personales, y por ello la ausencia del mismo o su negativa en su caso, no impide ni impedirá que Big Foot Print los trate (sus datos personales) en términos de la Ley y demás regulaciones que resulten aplicables.</p>
  <h1>OPCIONES Y MEDIOS QUE LA RESPONSABLE OFRECE A LOS TITULARES PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS PERSONALES/CORPORATIVOS.</h1>
  <p>
  Los datos personales de los Titulares, serán tratados bajo la más estricta confidencialidad, cuidando las medidas de seguridad administrativas, físicas y técnicas que la Responsable implemente en sus políticas y procedimientos de seguridad internos evitando la posible divulgación ilícita de datos y limitando su uso a lo establecido en el presente Aviso de Privacidad.
  </p>
  <h1>
	DERECHOS ARCO
  </h1>
  <p>
  Usted tiene derecho a (i) conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso); (ii) solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); (iii) que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); (iv) así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.
  </p>
  <h1>MEDIOS PARA EJERCER LOS DERECHOS ARCO DEL TITULAR</h1>
  <p>En cualquier momento usted o su representante legal debidamente acreditado, podrán ejercer los derechos de Acceso, Rectificación cuando consideren que son erróneos o inexactos, Cancelación en caso de que considere que los datos no son tratados de forma adecuada, Oposición en caso de que no quiera que sus datos sean tratados para finalidades específicas y/o Revocación cuando ya no sea su deseo como Titular que Big Foot Print continúe usando sus datos personales. El ejercicio de uno de ellos no impide el ejercicio del otro y ninguno es presupuesto para el ejercicio de otro.

Para ejercer sus derechos mencionados con anterioridad, deberá presentar su solicitud en nuestro domicilio ubicado en Calle Melchor Ocampo número 1500, Interior 2, Colonia El Vigía, C.P. 45140, Zapopan, Jalisco, o enviarla a nuestro departamento de datos personales al correo electrónico <a href="mailto:avisodeprivacidad@bfprint.com.mx">avisodeprivacidad@bfprint.com.mx</a>, con atención al Departamento de Protección de Datos.

La solicitud deberá contener los siguientes datos: (i) Nombre del titular, (ii) domicilio y dirección de correo electrónico para notificarle la respuesta a su solicitud, (iii) una descripción clara y precisa de los datos respecto de los cuales se busca ejercer el derecho correspondiente, (iv) el objeto de la solicitud, (v) cualquier elemento que facilite la localización de los datos, y se deberá anexar copia de su identificación, y en su caso el documento mediante el cual se acredite la representación legal.

El plazo de respuesta de su solicitud será de quince días hábiles contados a partir del día en que se recibió la solicitud de acceso, rectificación, cancelación y oposición correspondiente. La respuesta le será comunicada dentro del plazo antes mencionado a la dirección de correo electrónico que proporcionó en su solicitud o enviando carta al domicilio proporcionado en la misma. Una vez recibida dicha notificación tendrá un plazo de 15 días hábiles para hacerla efectiva en caso de ser procedente.

En cualquier momento podrá revocar el consentimiento que nos ha otorgado para el tratamiento de sus datos personales, de tal forma que dejemos hacer uso de los mismos. Para ello deberá presentar una solicitud en los términos mencionados para el ejercicio de los derechos ARCO. El procedimiento a seguir será el mismo que el previsto para el ejercicio de los derechos ARCO. En caso de ser procedente su solicitud sus datos dejaran de ser tratados por Big Foot Print.</p>
<h1>CAMBIOS AL AVISO DE PRIVACIDAD</h1>
<p>Cualquier cambio al presente Aviso de Privacidad, le será informado a través de los siguientes medios:

1) Mediante Correo electrónico que personalmente le enviemos, y
2) Mediante anuncio en la página web de la Responsable: https://www.bfprint.com.mx/

De igual manera, se le informa a los Titulares, que el Aviso de Privacidad podrá ser modificado en cualquier momento con la finalidad de atender novedades legislativas, así como modificación de políticas internas de Big Foot Print.

Si se llegaren a modificar las finalidades para las cuales se tratan sus datos personales, en caso de ser necesario se solicitará de nuevo su consentimiento ya sea expreso o tácito atendiendo al caso particular.</p>
<h1>CONSENTIMIENTO DEL TITULAR</h1>
<p>El Titular manifiesta que (i) el presente Aviso le ha sido dado a conocer por la Responsable y (ii) haber leído, entendido y acordado los términos expuestos en este Aviso, por lo que otorga su consentimiento para el tratamiento de sus Datos Personales de conformidad con el presente Aviso de Privacidad de manera tácita al enviar a la Responsable sus Datos y Documentos Personales.</p>
</div>

          </div>

        </div>
        <Footer />
      </>
  );
};

export default PrivacyPage;
