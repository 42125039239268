import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Contact from "./Contact";
import Hualla from "./Hualla";

const granItems = [
  "Gran formato y alta definición",
  "Señalética",
  "Sublimación",
  "Serigrafía",

  "Off-set digital",

  "Flexografía",

  "Lona",

  <>
    Impresión <br /> en vinil
  </>,

  <>
    Impresión <br /> en rígidos
  </>,

  "Displays",
  "Rotulación vehicular",
  <>
    Materiales <br /> Punto de venta
  </>,
];

// Maps of images for hover functionality
const leftImageMap = [
  "./image/gran1.png",
  "./image/senal1.png",
  "./image/subli1.png",
  "./image/sarig1.png",
  "./image/ofset1.png",
  "./image/flexo1.png",
  "./image/lono1.png",
  "./image/impres1.png",
  "./image/rigid1.png",
  "./image/disp1.png",
  "./image/rotul1.png",
  "./image/punto1.png",
];

const rightImageMap = [
  "./image/gran2.png",
  "./image/senal2.png",
  "./image/subli2.png",
  "./image/serig2.png",
  "./image/ofset2.png",
  "./image/flexo2.png",
  "./image/logo2.png",
  "./image/imprs2.png",
  "./image/rigid2.png",
  "./image/disp2.png",
  "./image/rotul2.png",
  "./image/punto2.png",
];

const HomePage = () => {
  const [hoverIndex, setHoverIndex] = useState(null);

  // Handlers for hover
  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  // ?mobile
  const handleMobileEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const handleMobileLeave = () => {
    setHoverIndex(null);
  };
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "#sobrenosotros") {
      const element = document.getElementById("sobrenosotros");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);

  return (
    <>
      <div>
        <Header />
        <div className="  bgsec1">
          <div className="colorimg">
            <img
              className="leftrighrcolor"
              src={require("./image/homeleftsec1.png")}
              alt="color"
              width="200px"
              height="auto"
            />
            <div className="Que">
              ¿Qué necesita <span className="Quespan"> imprimir </span>tu
              negocio hoy?
            </div>

            <img
              className="leftrighrcolor"
              src={require("./image/homerightsec1.png")}
              alt="color"
              width="200px"
              height="auto"
            />
          </div>
          {/* <div className="Que">¿Qué necesita imprimir tu negocio hoy?</div> */}

          <div className="boradimg">
            <img
              className="imgbord"
              src={
                hoverIndex !== null
                  ? require(`${leftImageMap[hoverIndex]}`)
                  : require("./image/bfsecleft.png")
              }
              alt="color "
              width="320px"
              height="auto"
            />

            <div className="wordbox dbnone">
              <div className="wordboxdf">
                {granItems.map((item, index) => (
                  <div
                    key={index}
                    className="granword"
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                    onTouchStart={() => handleMobileEnter(index)}
                    onTouchEnd={handleMobileLeave}
                  >
                    {item}
                  </div>
                ))}
              </div>

              <div className="imprhul">
                Impresión que deja <span className="helluitalic"> huella </span>
              </div>
            </div>
            <img
              className="imgbord"
              src={
                hoverIndex !== null
                  ? require(`${rightImageMap[hoverIndex]}`)
                  : require("./image/bfsecright.png")
              }
              alt="color"
              width="320px"
              height="auto"
            />
          </div>

          <div className="wordbox dblock">
            <div className="wordboxdf">
              {granItems.map((item, index) => (
                <div
                  key={index}
                  className="granword"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onTouchStart={() => handleMobileEnter(index)}
                  onTouchEnd={handleMobileLeave}
                >
                  {item}
                </div>
              ))}
            </div>

            <div className="imprhul">
              Impresión que deja <span className="helluitalic"> huella </span>
            </div>
          </div>
        </div>

        <div className="bgsec2">
          <div className="magbifidf">
            <div className="automlmrxp" id="sobrenosotros">
              <div className="magnifimpr">
                ¡Magnifica la <span className="Quespan"> impresión </span> de tu
                negocio!
              </div>
              <div className="bfsomos">
                En Bigfoot somos la extensión de tu departamento de marketing,
                ayudándote a imprimir de manera completa sin que tengas que
                preocuparte por los detalles. Te asesoramos en materiales,
                procesos, acabados y técnicas, asegurando que todo salga
                perfecto. Con nuestro equipo especializado y tiempos de
                respuesta, cubrimos todas tus necesidades de impresión, sin
                complicaciones.
              </div>

              <div className="btntext">
                <a href="https://wa.me/3318055852" target="_blank" className="btndf" rel="noreferrer">
                  <div className="btnEscr">
                    Escríbenos y descubre cómo podemos ayudarte
                  </div>
                  {/* <img
                    className=" whatsapp "
                    src={require("../components/image/wtsapbigfoot.png")}
                    alt="whatsapp"
                    width="50px"
                    height="50px"
                  /> */}
                  <div>
                    <i className="fab fa-whatsapp  whatsapp  "></i>
                  </div>
                </a>
              </div>
            </div>
            <div className="automlmrxp">
              <img
                className=" footside bg-white"
                src={require("./image/bfsec2.png")}
                alt="bfsec2"
                width="380px"
                height="auto"
              />
            </div>
          </div>
        </div>

        <div className="bfsec3">
          <div className="bfsec3padd ">
            <div className="bfsec3dfimg col-xxl-8" style={{'columnGap': '250px'}}>
              <div>
                <div className="necetext">
                  ¿Necesitas <span className="imapspan"> impactar </span> con tu
                  marca en la calle con espectaculares, muros o vallas?
                </div>
                <div className="grandf">
                  <div className="granword mtunset ">Gran formato</div>
                  <div className="granword  mt-3">
                    Impresión <br /> en lona
                  </div>
                  <div className="granword mt-3">Señalética</div>
                </div>
              </div>

              <div>
                <div className="necetext necemar">
                  ¿Necesitas brochures, flyers, tarjetas de presentación o
                  cualquier otro material de
                  <span className="imapspan">
                    {" "}
                    papelería corporativa{" "}
                  </span> ?{" "}
                </div>
                <div className="grandf">
                  <div className="granword mtunset">Off-set</div>
                  <div className="granword mt-3">
                    Impresión <br /> digital
                  </div>
                </div>
              </div>
              <div className="d-xl-none d-block">
                <div className="necetext">
                  ¿Quieres <span className="imapspan"> personalizar </span> tus
                  productos de forma atractiva y eficaz?
                </div>
                <div className="grandf">
                  <div className="granword mtunset ">Vinil</div>
                  <div className="granword mt-3">Packaging</div>
                  <div className="granword mt-3">Serigrafía</div>
                  <div className="granword mt-3">Off-set</div>

                  <div className="granword mt-3">Flexografía</div>
                </div>
              </div>
              <div>
                <div className="necetext necemar">
                  ¿Quieres que tu flotilla se vea
                  <span className="imapspan"> increíble </span> y de a conocer
                  tu negocio?
                </div>
                <div className="grandf">
                  <div className="granword mtunset">
                    Rotulación
                    <br />
                    vehicular
                  </div>

                  <div className="granword mt-3">
                    Impresión
                    <br />
                    en vinil HD
                  </div>
                </div>
              </div>
              <div>
                <div className="necetext necemar">
                  ¿Quieres que la gente
                  <span className="imapspan"> vea tu negocio</span>?{" "}
                </div>
                <div className="grandf">
                  <div className="impsubl mtunset">
                    <div className="granword ">Impresión a rígidos</div>
                    <div className="granword ">Sublimación</div>
                  </div>
                  <div className="granword mt-3">
                    Impresión <br /> en lona
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="d-none d-xl-block">
                <div className="necetext">
                  ¿Quieres <span className="imapspan"> personalizar </span> tus
                  productos de forma atractiva y eficaz?
                </div>

                <div className="granword ">Vinil</div>
                <div className="granword mt-3">Packaging</div>
                <div className="granword mt-3">Serigrafía</div>
                <div className="granword mt-3">Off-set</div>

                <div className="granword mt-3">Flexografía</div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Hualla />
        </div>
        <div>
          <Contact />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default HomePage;
